import React, { useEffect, useState, useRef } from 'react';
import Base from '../../utils/base';

export default function ModalConfirm({openModalAssignment, openSubmit}){
    var base = new Base()

    return(
        <div className="modal fade" id="modalConfirm" tabIndex="-1" aria-labelledby="modalConfirmLabel" aria-hidden="true">
            <div className="modal-dialog modal-dialog-centered p-4 p-lg-0-">
                <div className="modal-content rounded border-0 shadow-sm">
                    <div className="modal-body rounded p-0" style={{backgroundColor : '#F8F9FE'}}>
                        <div className={'row m-0'}>
                            <div className='col-12 p-3'>
                                <h3 className='m-0 mt-2 modalConfirmTitle text-primary' style={{fontFamily : 'InterBold'}}>Confirming Assignment</h3>
                                <p className='m-0 mt-3'>Are you sure you have done the assignment and trying to confirm it with your teacher ?</p>

                                <div className='row'>
                                    <div className='col pr-2'>
                                        <button className='btn btn-outline-primary w-100 rounded shadow-sm mt-4' data-dismiss="modal">No, I haven’t</button>
                                    </div>
                                    <div className='col pl-2'>
                                        <button className='btn btn-primary w-100 rounded shadow-sm mt-4' onClick={()=>openSubmit()}>Yes, I have</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}