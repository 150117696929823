import { useEffect, useState, useMemo } from 'react';
import Base from '../../../utils/base';

import { BrowserRouter as Router, Routes, Route, useLocation } from 'react-router-dom';
import Header from '../../../components/header';


export default function GradeBookDetail(){
    var base = new Base()

    function useQuery(){
        const {search} = useLocation()
        return useMemo(() => new URLSearchParams(search), [search]);
    }
    let query = useQuery()

    const [user_data, set_user_data] = useState({id : '', name : '', email : '', phone : '', image : {image_display : base.img_no_profile}})

    const [lesson_grade_arr, set_lesson_grade_arr] = useState([])
    const [subject_data, set_subject_data] = useState({id : '', name : ''})

    const [lesson_data_arr, set_lesson_data_arr] = useState([])

    function backBtn(){
        window.history.back()
    }

    useEffect(async ()=>{
        var check_user = await base.checkAuth()
        set_user_data(check_user.user_data)
    }, [])

    useEffect(async ()=>{
        if(user_data.id !== ''){
            get_data()
        }
    }, [user_data])

    async function get_data(){
        var url = '/grade/book?academic_year_id=' + query.get('academic_year') + '&subject_id=' + query.get('subject')
        var response = await base.request(url)
        if(response != null){
            if(response.status == 'success'){
                if(response.data != null){
                    var response_data = response.data
                    var lesson_arr = response_data.arr_lesson
                    for(let lesson of lesson_arr){
                        for(let assignment of lesson.arr_assignment){
                            var current_date = base.moment()
                            var deadline = base.moment(assignment.deadline_date)
                            assignment.status = 'Close'
                            assignment.type = 'close'
                            if(deadline.isAfter(current_date)){
                                assignment.status = 'Open'
                                assignment.type = 'open'
                            }
                        }
                    }

                    var lesson_grade = [
                        {title : 'Completion Progress', value : parseFloat(response_data.completion_progres).toFixed(2) + '%'},
                        {title : 'Overall Grade', value : response_data.total_score},
                    ]
                    set_lesson_data_arr(lesson_arr)
                    set_lesson_grade_arr(lesson_grade)
                    set_subject_data(response_data.subject)
                }
            }
        }
    }

    return(
        <div className='row'>

            <div className='col-12'>
                <Header title={(subject_data.name)+' Grade Book'} user_data={user_data} />
            </div>

            <div className='col-12 mt-5 pt-0 pt-lg-4'>
                <div className='row'>
                    <div className='col'>
                        <div className='bg-white shadow-sm rounded d-flex align-items-center justify-content-center backBtn' style={{cursor : 'pointer'}} onClick={backBtn}>
                            <h3 className='m-0'><i className="bi bi-arrow-left-short" style={{color : '#6F826E'}}></i></h3>
                        </div>
                    </div>
                    <div className='col-auto d-flex align-items-center justify-content-end'>
                        <div className='shadow-sm rounded bg-white py-2 px-4 text-center'>
                            <p className='m-0 text-center text-capitalize' style={{fontFamily : 'InterBold', color : 'black'}}>{subject_data.name}</p>
                        </div>
                    </div>
                </div>
            </div>

            {/* <div className='col-12 mt-3 mt-lg-5'>

                <div className='row'>
                    {
                        lesson_grade_arr.map((data, index)=>(
                            <div className={'col-6 col-lg mb-4' + (index % 2 == 0 ? ' pr-2 pr-lg-3' : ' pl-2 pl-lg-3')} key={index}>
                                <div className="card rounded shadow-sm h-100 w-100">
                                    <div className="card-body p-3 p-lg-4">
                                        <div className='d-flex align-items-center h-100'>
                                            <div>
                                                <h3 className='m-0 text-secondary gradeValue' style={{fontFamily : 'InterBold'}}>{data.value}</h3>
                                                <p className='m-0' style={{color : 'black'}}>{data.title}</p>
                                            </div>
                                        </div>
                                        <img className='position-absolute d-none d-lg-block' src={base.img_leaves} style={{height : '5rem', right : '-.25rem', top : 0}} />
                                    </div>
                                </div>
                            </div>
                        ))
                    }
                </div>

            </div> */}

            <div className='col-12 mt-4 mt-lg-5'>
                <div className="card rounded shadow-sm">
                    <div className={"card-body p-0"}>
                        <div className={'row m-0'}>
                            <img className='rounded' src={base.img_borderTop_primary} style={{width : '100%', height : '.75rem'}} />
                            <div className='col-12 p-2 pt-4 mb-5'>
                                <div className='row m-0'>
                                    {
                                        lesson_data_arr.map((data, index)=>(
                                            <div className={'col-12 p-0' + (index !== 0 ? ' mt-3' : '')} key={index}>
                                                <div className='row m-0'>
                                                    {/* <div className='col-12'>
                                                        <h5 className='m-0 gradeBookLessonTitle'>{data.lesson.name}</h5>
                                                    </div> */}
                                                    <div className='col-12'>
                                                        <div className="table-responsive">
                                                            <table className="table table-borderless">
                                                                <thead>
                                                                    <tr style={{backgroundColor : '#F9FAFB', borderBottom : '1px solid #EAEAEA'}}>
                                                                        <th className=''>Lesson</th>
                                                                        <th className=''>Assignment</th>
                                                                        <th className=''>Grade</th>
                                                                    </tr>
                                                                </thead>
                                                                <tbody>
                                                                    {
                                                                        data.arr_assignment.map((dataAssignment, indexAssignment)=>(
                                                                            <tr key={indexAssignment}>
                                                                                <td className='td-fit-content'>{data.lesson.name}</td>
                                                                                <td className=''>{dataAssignment.name}</td>
                                                                                <td className='td-fit-content'>
                                                                                    {
                                                                                        (dataAssignment.score != null) &&
                                                                                        <span className='px-3 py-1 rounded' style={{backgroundColor : '#EBF8FF', color : '#0085FF'}}>{dataAssignment.score}</span>
                                                                                    }
                                                                                </td>
                                                                            </tr>
                                                                        ))
                                                                    }
                                                                </tbody>
                                                            </table>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        ))
                                    }
                                </div>
                            </div>
                        </div>
                        
                    </div>
                </div>
            </div>

            
        </div>
    )
}