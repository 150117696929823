import React, { useEffect, useState, useRef } from 'react';
import Base from '../../utils/base';

import { BrowserRouter as Router, Routes, Route, useParams, useLocation } from 'react-router-dom';
import Header from '../../components/header';
import CustomBadge from '../../components/customBadge';
import LessonHeader from '../../components/lessonHeader';
import WebViewer from '@pdftron/webviewer'

export default function ModalSubmitAssignment({submission, changeFileInput, changeInput, postAssignment, arr_file, removeAttachment}){
    var base = new Base()

    return(
        <div className="modal fade" id="modalSubmit" tabIndex="-1" aria-labelledby="modalSubmitLabel" aria-hidden="true">
            <div className="modal-dialog modal-lg">
                <div className="modal-content rounded border-0 shadow-sm">
                    <div className="modal-body p-0">
                        <div className={'row m-0'}>
                            <img className='rounded' src={base.img_borderTop_primary} style={{width : '100%', height : '.75rem'}} />
                            <div className='col-12 p-3 pt-4 pb-5'>
                                <div className='row m-0'>
                                    <div className='col-12 mb-3'>
                                        <h5 className='m-0'><i className="bi bi-chat-square-dots-fill mr-3" style={{color : '#00000066'}}></i>Submit Assignment</h5>
                                    </div>

                                    <div className='col-12 mt-3'>
                                        <div className='row m-0'>
                                            <div className='col-12'>
                                                <p className='m-0' style={{color : 'black'}}>Submission</p>
                                                <textarea className="form-control rounded mt-2" rows={3} onChange={(e)=>changeInput(e.target.value)} style={{resize : 'none', border : '1px solid #EAEAEA'}} value={submission} placeholder=""></textarea>
                                            </div>
                                            <div className='col-12 mt-3'>
                                                <p className='m-0' style={{color : 'black'}}>Attachment</p>
                                                <input type="file" name="assignment_file" id="file_input" className="d-none" onChange={(event)=>changeFileInput(event)} />
                                                <button className='btn btn-sm btn-secondary text-white rounded shadow-sm px-5 mt-2' onClick={()=>base.$('#file_input').trigger('click')}>Choose File</button>

                                                <div className='row'>
                                                    <div className='col-12'>
                                                        <div className='row'>
                                                            {
                                                                arr_file.map((data, index)=>(
                                                                    <div className='col-auto text-center mt-3' key={index}>
                                                                        <div className='p-2 position-relative' style={{border : '1px solid #EAEAEA'}}>
                                                                            <i className="bi bi-x-circle-fill position-absolute" style={{right : 0, top : 0, cursor : 'pointer'}} onClick={()=>removeAttachment(index)}></i>
                                                                            <h3 className='m-0'><i className="bi bi-file-earmark-fill"></i></h3>
                                                                            <p className='m-0'>{data.name}</p>
                                                                        </div>
                                                                    </div>
                                                                ))
                                                            }
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>


                                    <div className='col-12 mt-4'>
                                        <div className='row m-0'>
                                            <div className='col'>
                                                <button className='btn btn-primary rounded shadow-sm px-5' onClick={postAssignment}>Submit</button>
                                            </div>
                                        </div>
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}