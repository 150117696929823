import { useEffect, useState } from 'react';
import Base from '../../utils/base';

import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Header from '../../components/header';
import CustomBadge from '../../components/customBadge';
import ModalConfirm from './modalConfirm';


export default function LessonsIndex(){
	var base = new Base()

	const [user_data, set_user_data] = useState({id : '', name : '', email : '', phone : '', image : {image_display : base.img_no_profile}, total_today_reward : 0, current_class_student : {is_online : 0} })

	const [lesson_arr, set_lesson_arr] = useState([])
	const [search, set_search] = useState([])

	const [has_done_lesson, set_has_done_lesson] = useState(true)

	const [page, set_page] = useState(0)
	const [is_prev, set_is_prev] = useState(true)
	const [is_next, set_is_next] = useState(true)

	const search_callback = ''

	function accordionBtn(index){
		var data_index = lesson_arr[index]
		var initSelected = data_index.is_open
		for(var x in lesson_arr){
			lesson_arr[x].is_open = false
		}
		lesson_arr[index].is_open = !initSelected
		
		base.update_array(lesson_arr, set_lesson_arr, data_index, index)
	}

	async function toDetail(index, index_subject_type, indexDtl){
		var data = lesson_arr[index].arr_subject_type[index_subject_type].arr_assignment[indexDtl]
		window.location.href = '/lessons/detail?id=' + data.id + '&type=' + (data.type !== 'assignment' ? 'task' : data.type)
	}

	useEffect(async ()=>{
		var check_user = await base.checkAuth()
		set_user_data(check_user.user_data)
		
		var check_student_access_date = check_user.user_data.is_student_access
		if(!check_student_access_date){
			window.location.href = '/'
		}

	}, [])

	useEffect(()=>{
		set_lesson_arr([])
		if(user_data.id !== ''){
			get_assignment()
		}
	}, [user_data, search])

	useEffect(async ()=>{
		set_lesson_arr([])
		get_assignment()
	}, [page])

	async function get_assignment(){
		var url = '/lesson?search=' + search + '&num_data=2' + '&counter=' + page
		var response = await base.request(url)
		if(response != null){
			if(response.status == 'success'){
				var response_data = response.data.arr
				for(var x in response_data){
					var subject_type = response_data[x].arr_subject_type
					response_data[x].is_show = true
					for(var type of subject_type){
						var assignment_data = type.arr_assignment
						for(var assignment of assignment_data){
							assignment.deadline_date_format = (assignment.deadline_date != null ? base.moment(assignment.deadline_date).format('DD/MM/YYYY') : '-')

							assignment.status_assignment = {title : 'No Submission', type : 'info', data : ''}
							if(assignment.assignment_submitted != null){
								if(assignment.assignment_submitted.assessment_status != null){
									var title = assignment.assignment_submitted.assessment_status.name
									var type = (assignment.assignment_submitted.assessment_status.data === 'done' ? 'success' : assignment.assignment_submitted.assessment_status.data === 'on_checking' ? 'warning' : assignment.assignment_submitted.assessment_status.data === 'need_correction' ? 'info' : '')
	
									assignment.status_assignment = {title : title, type : type, data : assignment.assignment_submitted.assessment_status.data}
								}
							}
						}
					}

					response_data[x].prev_lesson_done = false

					response_data[0].prev_lesson_done = true
					if(x != 0){
						response_data[x].prev_lesson_done = response_data[x-1].has_done
					}

					set_has_done_lesson(response_data[response_data.length-1].has_done)
				}
				set_lesson_arr(response_data)
				set_is_prev(response.data.previous_page)
				set_is_next(response.data.next_page)
			}
		}
	}

	function changeSearch(value){
		set_search(value)
	}

	async function lessonNav(type){
		if(type === 'next'){
			set_page(page+1)
		}
		if(type === 'back'){
			set_page(page-1)
		}
	}

	async function toggleShow(index){
		var data_index = lesson_arr[index]
        var initShow = data_index.is_show
        // for(var x in lesson_arr){
        //     lesson_arr[x].is_show = true
        // }
        lesson_arr[index].is_show = !initShow

        base.update_array(lesson_arr, set_lesson_arr, data_index, index)
	}

	const [selected_assignment, set_selected_assignment] = useState(null)

	useEffect(async ()=>{
		if(selected_assignment != null){
			if(selected_assignment.status_assignment.data === ''){
				base.$('#modalConfirm').modal('show')
			}
		}
	}, [selected_assignment])

	async function submitAssignment(type, index='', index_subject_type='', indexDtl=''){
		if(!user_data.current_class_student.is_online){
			if(type === 'open_modal'){
				set_selected_assignment(lesson_arr[index].arr_subject_type[index_subject_type].arr_assignment[indexDtl])
			}
			else if(type === 'post'){
				var url = '/assessment/assignment'
				var data = {
					type : 'new'
				}

				if(selected_assignment.type === 'assignment'){
					data.assignment_agreement = {id : selected_assignment.id}
				}
				else{
					data.task_agreement = {id : selected_assignment.id}
				}

				var response = await base.request(url, 'post', data)
				if(response != null){
					if(response.status == 'success'){
						window.location.reload()
					}
				}
			}
		}
	}

	return(
		<div className='row'>

			<div className='col-12'>
				<Header title={'Lesson'} user_data={user_data} />
			</div>

			<div className='col-12 mt-5 pt-4'>
				<div className="input-group shadow bg-white rounded">
					<div className="input-group-prepend">
						<span className="input-group-text border-0 pl-4 bg-transparent" id="inputGroup-sizing-default"><i className="bi bi-search"></i></span>
					</div>
					<input type="text" className="form-control border-0 bg-transparent searchInput" placeholder='Search by Activity ...' value={search} onChange={(e)=>changeSearch(e.target.value)} />
				</div>
			</div>

			<div className='col-12 mt-5'>
				<div className='row'>

					{
						lesson_arr.map((data, index)=>(
							<div className='col-12 col-lg-6' key={index}>
								<div className={"card rounded" + (data.is_show ? ' h-100' : '')}>
									<div className={"card-header p-0" + (!data.is_show ? ' rounded border-0' : '')} id={"heading" + (index)} style={{cursor : 'pointer', backgroundColor : 'unset'}} onClick={()=>toggleShow(index)}>
										<div className='row m-0'>
											<div className='col p-0'>
												{/* <img className='position-absolute' src={base.img_borderLeft_primary} style={{height : '100%', left : 0, top : 0}} /> */}
													<div className='row m-0'>
														<div className='col-12 p-0 py-0'>
															<div className='row m-0'>
																<div className='col-4 bg-secondary position-relative' style={{borderTopLeftRadius : '.75rem', borderBottomLeftRadius : (!data.is_show ? '.75rem' : '')}}>
																	<img className='position-absolute' src={base.img_bg_lesson} style={{height : '100%', right : '-1rem', top : 0}} />

																	<div className='position-absolute' style={{right : '.5rem'}}>
																		<div className='row'>
																			<div className='col-auto p-0'>
																				<img className='' src={base.img_eye} style={{height : 'auto'}} />
																			</div>
																			<div className='col-auto p-0'>
																				<img className='' src={base.img_eye} style={{height : 'auto'}} />
																			</div>
																		</div>
																	</div>
																</div>
																<div className='col py-2'>
																	<div className='row m-0'>
																		<div className='col-auto d-flex justify-content-center align-items-center'>
																			<h5 className='m-0 font-weight-normal' style={{color : 'black'}}>Lesson</h5>
																		</div>
																		<div className='col-auto p-0'>
																			<div className='rounded d-flex justify-content-center align-items-center' style={{height : '2rem', width : '2rem', backgroundColor : '#EAEAEA'}}>
																				<p className='m-0 text-secondary' style={{fontFamily : 'BlackHanSans', fontSize : '1rem'}}>{data.name.replace('Lesson', '')}</p>
																			</div>
																		</div>
																	</div>
																</div>
																<div className='col-auto d-flex align-items-center'>
																	<i className={"fas fa-chevron-" + (data.is_show ? 'up' : 'down')}></i>
																</div>
															</div>
														</div>
													</div>
											</div>
										</div>
									</div>

									{
										data.is_show &&
										<div className={"card-body px-3 pb-3 pt-0"}>
											<div className='row'>
												<div className='col-12'>
													<div className='row m-0'>
														{
															data.arr_subject_type.map((subject_type_data, index_subject_type)=>(
															<>
															{
																subject_type_data.arr_assignment.length > 0 &&
																<>
																	<div className='col-12 p-0' key={index_subject_type}>
																		<div className='row'>
																			<div className='col-12 px-3 py-3 px-lg-4 py-lg-2 d-flex align-items-center' style={{borderBottom : '2px solid #eaeaea'}}>
																				<div>
																					<p className={'m-0 py-1 px-3 py-lg-2 px-lg-5 text-center text-capitalize lessonType' + (subject_type_data.subject_type.type === 'Daily Lesson' ? ' dailyLesson' : ' weeklyLesson')} style={{borderRadius : '2rem', fontSize : '.75rem'}}>{subject_type_data.subject_type.name}</p>
																				</div>
																			</div>
																		</div>
																	</div>
																	<div className='col-12 p-0'>
																		<div className='row'>
																			{
																					subject_type_data.arr_assignment.length > 0 ?
																					<>
																						{
																							subject_type_data.arr_assignment.map((dataDtl, indexDtl)=>(
																								<div className='col-12 px-4 py-3' key={indexDtl} style={{borderBottom : '2px solid #eaeaea', backgroundColor : '#F8F8F8'}}>
																										<div className='row'>
																											<div className='col-auto d-flex align-items-center'>
																												<h6 className='m-0 lessonAssignmentChecked' style={{cursor : 'pointer'}} onClick={()=>submitAssignment('open_modal', index, index_subject_type, indexDtl)}>
																												{
																													dataDtl.assignment_submitted != null ?
																													<i className="bi bi-check-square-fill text-primary"></i>
																													:
																													<i className="bi bi-square"></i>
																												}
																												</h6>
																											</div>
																											<div className='col'>
																												<a href={'/lessons/detail?id=' + dataDtl.id + '&type=' + (dataDtl.type !== 'assignment' ? 'task' : dataDtl.type)} style={{textDecoration : 'none'}}>
																													<div className='row'>
																														<div className='col px-2 d-flex align-items-center'>
																															<div>
																																<p className='m-0' style={{color : 'black', fontFamily : 'InterBold', fontSize : '.75rem'}}>
																																	<span className='lessonAssignmentTitle'>
																																	{dataDtl.type === 'assignment' ? dataDtl.assignment_group.subject.name : dataDtl.project_agreement.subject.name} : {dataDtl.type === 'assignment' ? dataDtl.name : (dataDtl.project_agreement.name + ' - ' + dataDtl.title)}
																																	</span>
																																</p>
																																{/* <p className='m-0 d-block d-lg-none' style={{color : '#413F3F', fontSize : '.5rem'}}>Deadline : {dataDtl.deadline_date_format}</p> */}
																															</div>
																														</div>
																														<div className='col-auto d-flex align-items-center justify-content-end'>
																															<div>
																																{/* <p className='m-0 d-none d-lg-block' style={{color : '#413F3F'}}>DUE : {dataDtl.deadline_date_format}</p> */}
																																<div className='d-block d-lg-none'>
																																	<CustomBadge title={dataDtl.status_assignment.title} type={dataDtl.status_assignment.type} size={'small'} />
																																</div>
																															</div>

																														</div>
																													</div>
																												</a>
																											</div>
																										</div>
																								</div>
																							))
																						}
																					</>
																					:
																					<></>
																				}
																		</div>
																	</div>
																</>
															}
															</>
															))
														}

													</div>
												</div>
											</div>
										</div>
									}
								</div>
							</div>
						))
					}
					<div className='col-6'>

					</div>
				</div>
			</div>

			<div className='col-12 mt-4'>
				<div className='row'>
					<div className='col'>
						<button className='btn btn-warning shadow-sm px-3 px-lg-5' style={{borderRadius : '5rem', color : '#4F4CD4'}} onClick={()=>lessonNav('back')} disabled={!is_prev}><i className="bi bi-arrow-left-short"></i> Back Lesson</button>
					</div>
					<div className='col text-right'>
						<button className='btn btn-warning shadow-sm px-3 px-lg-5' style={{borderRadius : '5rem', color : '#4F4CD4'}} onClick={()=>lessonNav('next')} disabled={!is_next}>Next Lesson <i className="bi bi-arrow-right-short"></i></button>
					</div>
				</div>
			</div>

			<ModalConfirm openSubmit={()=>submitAssignment('post')} />
			
		</div>
	)
}