import React, { useEffect, useState, useRef } from 'react';
import Base from '../../utils/base';


export default function ModalSuccess(){
    var base = new Base()

    return(
        <div className="modal fade" id="modalSuccess" tabIndex="-1" aria-labelledby="modalSuccessLabel" aria-hidden="true" data-backdrop="static" data-keyboard="false">
            <div className="modal-dialog modal-dialog-centered p-4 p-lg-0-">
                <div className="modal-content rounded border-0 shadow-sm">
                    <div className="modal-body rounded p-0" style={{backgroundColor : '#F8F9FE'}}>
                        <div className={'row m-0'}>
                            <div className='col-12 p-3 pt-4 pb-5 text-center'>
                                <img src={base.img_study_1} className='modalSuccessImg' />
                                <h2 className='m-0 mt-2 modalSuccessTitle' style={{color : '#575A89'}}>Assignment Submitted !</h2>
                                <p className='m-0 mt-3'>Thankyou for submitting your assignment on time. Please kindly wait for the teacher to grade your assignment.</p>

                                <button className='btn btn-primary w-100 rounded shadow-sm mt-4' onClick={()=>window.location.href = '/lessons'}>Back to Dashboard</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}