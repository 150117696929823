import { useEffect, useState } from 'react';
import Base from '../utils/base';
import CustomBadge from './customBadge';


export default function LessonHeader({title, type, backBtn, bgColor, color, icon}){
    var base = new Base()

    return(
        <>
        <div className='row'>
            <div className='col'>
                <div className='bg-white shadow-sm rounded d-flex align-items-center justify-content-center backBtn' style={{cursor : 'pointer'}} onClick={backBtn}>
                    <h3 className='m-0'><i className="bi bi-arrow-left-short" style={{color : '#6F826E'}}></i></h3>
                </div>
            </div>
            <div className='col-auto text-right d-flex align-items-center justify-content-center'>
                {/* <CustomBadge title={title} type={type} /> */}
                <div className={'badgeLesson position-relative'} style={{backgroundColor : bgColor, borderRadius : '2rem'}}>
                    <p className='m-0' style={{color : color, fontFamily : 'PoetsenOne'}}>{title}</p>

                    {
                        icon !== '' &&
                        <div className='p-0 position-absolute' style={{right : '.25rem', top : '-.25rem'}}>
                            <img src={icon} style={{height : '2.5rem'}} />
                        </div>
                    }
                </div>
            </div>
        </div>
        </>
    )
}