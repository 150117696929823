import React, { useEffect, useState, useRef } from 'react';
import Base from '../../utils/base';

import { BrowserRouter as Router, Routes, Route, useParams, useLocation } from 'react-router-dom';
import Header from '../../components/header';
import CustomBadge from '../../components/customBadge';
import LessonHeader from '../../components/lessonHeader';
import WebViewer from '@pdftron/webviewer'
import ModalSubmitAssignment from './modalSubmit';
import ModalSuccess from './modalSuccess';
import ModalConfirm from './modalConfirm';
import ModalViewSkill from './modalViewSkill';

export default function LessonsDetail(){
	var base = new Base()
	const viewerDiv = useRef(null)

	const [user_data, set_user_data] = useState({id : '', name : '', email : '', phone : '', image : {image_display : base.img_no_profile}, current_class_student : {is_online : 0}})
	const [assignment_data, set_assignment_data] = useState(
		{id : '', name : '', type : '', description : '', deadline_date_format : '',
		assign_teacher : {user : {name : ''}}, assignment_submitted : [], assignment_group : {
			lesson : {name : ''},
			subject : {name : ''}
		},
		subject_type : '',
		status_assignment : {title : '', type : ''},
		last_assignment_submitted : null,
		first_assignment_submitted : null,
		project_agreement : {name : ''},
		type : '',
		title : ''
	})
	const [current_page, set_current_page] = useState('')
	const [submission, set_submission] = useState('')

	const [assignment_type, set_assignment_type] = useState('')
	const [subject_name, set_subject_name] = useState('')
	
	const [assignment_status_data, set_assignment_status_data] = useState('')

	const [viewType, set_viewType] = useState('detail')

	const [grade_skill_arr, set_grade_skill_arr] = useState([])
	const [teacher_notes, set_teacher_notes] = useState('')

	const [disable_btn_submit, set_disable_btn_submit] = useState(false)

	function useQuery(){
		const {search} = useLocation()
		return React.useMemo(() => new URLSearchParams(search), [search]);
	}
	
	let query = useQuery()
	
	useEffect(async ()=>{
		var check_user = await base.checkAuth()
		set_user_data(check_user.user_data)
		
		var check_student_access_date = check_user.user_data.is_student_access
		if(!check_student_access_date){
			window.location.href = '/'
		}

		// base.$('#modalConfirm').modal('show')
	}, [])
	
	useEffect(()=>{
		if(user_data.id !== ''){
			if(query.get('type') === 'assignment')
				get_data()
			else{
				get_data_task()
			}
		}
	}, [user_data])

	async function get_data(){
		var url = '/assignment/agreement?id=' + query.get('id')
		var response = await base.request(url)
		if(response != null){
			if(response.status == 'success'){
				var response_data = response.data
				response_data.deadline_date_format = base.moment(response_data.deadline_date).format('dddd, DD MMMM YYYY') + ', Pk. ' + base.moment(response_data.deadline_date).format('HH:mm a')
				response_data.subject_type = response_data.assignment_group.subject.subject_type.name

				response_data.status_assignment = {title : 'No Submission', bg_color : '#EAEAEA', color : '#999999', icon : ''}
				if(response_data.last_assignment_submitted != null){
					var title = response_data.last_assignment_submitted.assessment_status.name

					var bg_color = ''
					var icon = ''
					if(response_data.last_assignment_submitted.assessment_status.data === 'done'){
						bg_color = '#60B158D4'
						icon = base.img_yellow_eye
					}
					else if(response_data.last_assignment_submitted.assessment_status.data === 'on_checking'){
						bg_color = '#2798FFBF'
						icon = base.img_baloon
					}
					else if(response_data.last_assignment_submitted.assessment_status.data === 'need_correction'){
						bg_color = '#FF973C'
						icon = base.img_tiny_correction
					}
					else if(response_data.last_assignment_submitted.assessment_status.data === 'need_much_correction'){
						bg_color = '#BE6BFFBD'
						icon = base.img_eye_box
					}

					response_data.status_assignment = {title : title, bg_color : bg_color, color : 'white', icon : icon}
					
					set_assignment_status_data(response_data.last_assignment_submitted.assessment_status.data)

					var skill_category = response_data.last_assignment_submitted.arr_skill_category
					var notes = ''
					for(var x in skill_category){
						var skill = skill_category[x].arr_skill
						for(var y in skill){
							skill[y].score = (skill[y].grade_skill != null ? skill[y].grade_skill.score : 0)
						}
					}
					notes = (skill_category[0].arr_skill[0].grade_skill != null ? skill_category[0].arr_skill[0].grade_skill.comment : '-')
					set_grade_skill_arr(skill_category)
					set_teacher_notes(notes)
				}

				if(response_data.assignment_submitted.length > 0){
					response_data.first_assignment_submitted = response_data.assignment_submitted[0]
					response_data.first_assignment_submitted.submitted_date_format = base.moment(response_data.first_assignment_submitted.created_at).format('dddd, DD MMMM YYYY') + ', Pk. ' + base.moment(response_data.first_assignment_submitted.created_at).format('HH:mm a')
					set_submission(response_data.first_assignment_submitted.description)
				}

				if(response_data.last_assignment_submitted != null){
					response_data.last_assignment_submitted.updated_date_format = base.moment(response_data.last_assignment_submitted.updated_at).format('dddd, DD MMMM YYYY') + ', Pk. ' + base.moment(response_data.last_assignment_submitted.updated_at).format('HH:mm a')
					if(response_data.assignment_type.name !== 'discussion'){
						response_data.last_assignment_submitted.score = 'N/A'
					}
				}


				set_assignment_type(response_data.assignment_type.data)

				set_assignment_data(response_data)

				if(response_data.last_assignment_submitted != null){
					set_submission(response_data.last_assignment_submitted.description)

					var file = ''
                    var file_type = 'pdf'
    
                    if(response_data.last_assignment_submitted.file_name != null){
                        file = response_data.last_assignment_submitted.file_name
                    }
                    
                    var doc_url = 'assignment/submitted'

                    file_type = file.split('.')[1]
                    if(file_type !== 'pdf'){
                        doc_url += '/image'
                    }
					
					if(response_data.last_assignment_submitted.file_name != null){
						WebViewer({
							path : '/lib', initialDoc : base.url_photo(doc_url, response_data.last_assignment_submitted.file_name),
						}, viewerDiv.current).then((instance) => {
							instance.UI.disableElements(['toolbarGroup-Shapes']);
							instance.UI.disableElements(['toolbarGroup-Edit']);
							instance.UI.disableElements(['toolbarGroup-Insert']);
							instance.UI.disableElements(['ribbons']);
							instance.UI.disableElements(['toolbarGroup-Annotate']);
						})
					}

				}

				set_subject_name(response_data.assignment_group.subject.name)

				set_current_page(response_data.assignment_group.lesson.name + ' - ' + response_data.assignment_group.subject.name)
			}
		}
	}

	async function get_data_task(){
		var url = '/task/agreement?id=' + query.get('id')
		var response = await base.request(url)
		if(response != null){
			if(response.status == 'success'){
				var response_data = response.data
				// response_data.deadline_date_format = base.moment(response_data.deadline_date).format('dddd, DD MMMM YYYY') + ', Pk. ' + base.moment(response_data.deadline_date).format('HH:mm a')
				response_data.subject_type = response_data.project_agreement.subject.subject_type.name


				response_data.status_assignment = {title : 'No Submission', bg_color : '#EAEAEA', color : '#999999', icon : ''}
				if(response_data.last_assignment_submitted != null){
					var title = response_data.last_assignment_submitted.assessment_status.name

					var bg_color = ''
					var icon = ''
					if(response_data.last_assignment_submitted.assessment_status.data === 'done'){
						bg_color = '#60B158D4'
						icon = base.img_yellow_eye
					}
					else if(response_data.last_assignment_submitted.assessment_status.data === 'on_checking'){
						bg_color = '#2798FFBF'
						icon = base.img_baloon
					}
					else if(response_data.last_assignment_submitted.assessment_status.data === 'need_correction'){
						bg_color = '#FF973C'
						icon = base.img_tiny_correction
					}
					else if(response_data.last_assignment_submitted.assessment_status.data === 'need_much_correction'){
						bg_color = '#BE6BFFBD'
						icon = base.img_eye_box
					}

					response_data.status_assignment = {title : title, bg_color : bg_color, color : 'white', icon : icon}

					set_assignment_status_data(response_data.last_assignment_submitted.assessment_status.data)

					var skill_category = []
					var notes = ''

					if(response_data.last_assignment_submitted.arr_skill_category != null){
						for(var x in skill_category){
							var skill = skill_category[x].arr_skill
							for(var y in skill){
								skill[y].score = ''
								if(skill[y].grade_skill != null)
									skill[y].score = skill[y].grade_skill.score
							}
						}
	
						notes = response_data.last_assignment_submitted.comment
					}


					set_grade_skill_arr(skill_category)
					set_teacher_notes(notes)
				}

				if(response_data.assignment_submitted.length > 0){
					response_data.first_assignment_submitted = response_data.assignment_submitted[0]
					response_data.first_assignment_submitted.submitted_date_format = base.moment(response_data.first_assignment_submitted.created_at).format('dddd, DD MMMM YYYY') + ', Pk. ' + base.moment(response_data.first_assignment_submitted.created_at).format('HH:mm a')
					set_submission(response_data.first_assignment_submitted.description)
				}

				if(response_data.last_assignment_submitted != null){
					response_data.last_assignment_submitted.updated_date_format = base.moment(response_data.last_assignment_submitted.updated_at).format('dddd, DD MMMM YYYY') + ', Pk. ' + base.moment(response_data.last_assignment_submitted.updated_at).format('HH:mm a')
				}

				set_assignment_type('discussion')

				set_assignment_data(response_data)

				if(response_data.last_assignment_submitted != null){
					set_submission(response_data.last_assignment_submitted.description)
					if(response_data.last_assignment_submitted.file_name != null){
						WebViewer({
							path : '/lib', initialDoc : base.url_photo('assignment/submitted', response_data.last_assignment_submitted.file_name),
						}, viewerDiv.current).then((instance) => {
							instance.UI.disableElements(['toolbarGroup-Shapes']);
							instance.UI.disableElements(['toolbarGroup-Edit']);
							instance.UI.disableElements(['toolbarGroup-Insert']);
							instance.UI.disableElements(['ribbons']);
							instance.UI.disableElements(['toolbarGroup-Annotate']);
						})
					}
				}

				set_subject_name(response_data.project_agreement.subject.name)
				set_current_page(response_data.lesson.name + ' - ' + response_data.project_agreement.subject.name)
			}
		}
	}

	function backBtn(){
		if(viewType === 'detail'){
			window.location.href = '/lessons'
		}
		else if(viewType === 'upload'){
			set_viewType('detail')
		}
	}

	function getImgBase(file, callback){
		let reader = new FileReader();
		reader.readAsDataURL(file);
		reader.onload = function () {
			callback(reader.result)
		};
		reader.onerror = function (error) {
			console.log('Error: ', error);
		};
	}

	const [arr_file, set_arr_file] = useState([])
	const [file, set_file] = useState(null)
	const [file_index, set_file_index] = useState(null)
	const [upload_type, set_upload_type] = useState(null)

	async function changeFileInput(event){
		var count = 0

		for(let temp of event.target.files){
			await getImgBase(temp, (result)=>{
				var tmp = {base64 : result, name : temp.name}
				base.add_array(arr_file, set_arr_file, tmp)
			})
		}
	}
	
	async function openModalConfirm(type){
		if(user_data.current_class_student.is_online){
			set_upload_type(type)
			if(query.get('type') === 'assignment'){
				set_viewType('upload')
				// if(assignment_data.assignment_type.data !== 'ungraded'){
				// }
				// else if(assignment_data.assignment_type.data === 'ungraded'){
				// 	base.$('#modalConfirm').modal('show')
				// }
			}
			else{
				if(assignment_data.type === 'upload'){
					base.$('#modalConfirm').modal('hide')
					set_viewType('upload')
				}
				else if(assignment_data.type === 'presentation'){
					postAssignment()
				}
			}
		}
		else{
			if(assignment_status_data !== 'done'){
				if(assignment_status_data !== 'on_checking'){
					base.$('#modalConfirm').modal('show')
				}
			}
		}
	}

	async function changeInput(value){
		set_submission(value)
	}

	async function postAssignment(){
		var file_data = []
		for(var x in arr_file){
			file_data.push({
				file : {
					file_name : arr_file[x].name,
					file : arr_file[x].base64
				}
			})
		}

		set_disable_btn_submit(true)

		var url = '/assessment/assignment'
		var data = {
			type : upload_type,
			description : submission,
			arr_file : file_data
		}
		if(query.get('type') === 'assignment'){
			data.assignment_agreement = {id : assignment_data.id}
		}
		else if(query.get('type') === 'task'){
			data.task_agreement = {id : query.get('id')}
		}

		var response = await base.request(url, 'post', data)
		set_disable_btn_submit(false)
		if(response != null){
			if(response.status == 'success'){
				base.$('#modalSubmit').modal('hide')
				base.$('#modalConfirm').modal('hide')
				base.$('#modalSuccess').modal('show')
				// window.location.reload()
			}
		}
	}

	async function removeAttachment(index){
		base.remove_array(arr_file, set_arr_file, index)
	}

	async function submitAssignment(){
		if(user_data.current_class_student.is_online){
			if(query.get('type') === 'assignment'){
				base.$('#modalConfirm').modal('hide')
				set_viewType('upload')
				// if(assignment_data.assignment_type.data !== 'ungraded'){
				// }
				// else if(assignment_data.assignment_type.data === 'ungraded'){
				// 	postAssignment()
				// }
			}
			else{
				if(assignment_data.type === 'upload'){
					base.$('#modalConfirm').modal('hide')
					set_viewType('upload')
				}
				else if(assignment_data.type === 'presentation'){
					postAssignment()
				}
			}
		}
		else{
			postAssignment()
		}
	}

	function viewGradeSkill(){
		base.$('#viewSkillModal').modal('show')
	}

	return(
		<div className='row'>

			<div className='col-12'>
				<Header title={current_page} user_data={user_data} />
			</div>

			<div className='col-12 mt-5 pt-4'>
				<LessonHeader title={assignment_data.status_assignment.title} bgColor={assignment_data.status_assignment.bg_color} color={assignment_data.status_assignment.color} icon={assignment_data.status_assignment.icon} backBtn={()=>backBtn()} />
			</div>

			{
				viewType === 'detail' ?
				<>
					<div className='col-12 mt-3 mt-lg-5'>
						<div className="card rounded shadow-sm">
							<div className={"card-body p-0"}>
								<div className='row m-0'>
									<img className='rounded' src={base.img_borderTop_primary} style={{width : '100%', height : '.75rem'}} />
									<div className='col-12 px-4'>
										<div className='row mt-2'>
											<div className='col-12 p-2 p-lg-4 pb-5 mb-3'>
												<img className='position-absolute d-none d-lg-block' src={base.img_leaves} style={{height : '5rem', right : 0, top : '-.5rem'}}  />
												<div className='row m-0'>
													<div className='col-12 mb-0 mb-lg-4'>
														<div className='row'>
															<div className='col-auto px-0 px-lg-3 d-none d-lg-block'>
																<h4 className='m-0' style={{cursor : (assignment_status_data === '' ? 'pointer' : 'default')}} onClick={()=>openModalConfirm('new')}>
																	{
																		assignment_data.assignment_submitted.length > 0 ? 
																		<i className="bi bi-check-square-fill text-primary"></i>
																		:
																		<i className="far fa-square" style={{color : '#8C9196'}}></i>
																	}
																</h4>
															</div>
															<div className='col p-0'>
																<h4 className='m-0 assignmentTitle font-weight-normal' style={{color : '#777777', fontWeight : 'normal'}}>{query.get('type') === 'assignment' ? assignment_data.name : assignment_data.title + ' - ' + assignment_data.project_agreement.name}</h4>
															</div>

														</div>
													</div>
													<div className='col-12 px-0 px-lg-3'>
														<div className='row'>
															<div className='col-12 mt-2 mt-lg-3'>
																<div className='row mt-2'>
																	<div className='col-12 col-lg-auto'>
																		<p className='m-0'><i className="fas fa-clock mr-2"></i>Deadline on {assignment_data.deadline_date_format}</p>
																	</div>
																	<div className='col-6 col-lg d-flex justify-content-lg-center mt-2 mt-lg-0'>
																		<p className='m-0'><i className="fas fa-clipboard-check mr-2"></i>{assignment_data.assign_teacher.user.name}</p>
																	</div>
																	<div className='col-6 col-lg d-flex justify-content-lg-center mt-2 mt-lg-0'>
																		<p className='m-0'><i className="fas fa-clipboard-check mr-2"></i>{subject_name}</p>
																	</div>
																</div>
															</div>
															<div className='col-12 mt-2 mt-lg-4'>
																<div className='row'>
																	<div className='col-12 mt-2'>
																		<p className='m-0' style={{color : '#777777', fontFamily : 'InterBold'}}>Information Course</p>
																		<p className='m-0 mt-2' style={{color : '#777777', textAlign : 'justify'}}>{assignment_data.description}</p>
																		{/* <img src={base.img_img_1} className='mt-4' style={{height : '10rem'}} /> */}
																	</div>
																	{/* <div className='col-12 mt-4'>
																		<p className='m-0 font-weight-bold' style={{color : '#777777'}}>Detail Assignment</p>
																		<div className='col-12 p-0 mt-2' dangerouslySetInnerHTML={{__html: assignment_data.description}}></div>
																	</div> */}

																	{
																		user_data.current_class_student.is_online ?
																		<div className='col-12 mt-4'>
																			<button className='btn btn-primary rounded py-2 shadow px-5 d-none d-lg-block' onClick={()=>openModalConfirm('new')} disabled={(assignment_status_data !== '' ? true : false)}>Submit Assignment</button>
																			<button className='btn btn-primary rounded py-2 shadow px-5 d-block d-lg-none w-100' onClick={()=>openModalConfirm('new')} disabled={(assignment_status_data !== '' ? true : false)}>Submit Assignment</button>
																		</div> 
																		: <></>
																	}
																</div>
															</div>
														</div>
													</div>
												</div>
												
											</div>
										</div>
									</div>

								</div>
							</div>
						</div>
					</div>

					{
						user_data.current_class_student.is_online ?
						<>	
							{
								assignment_data.assignment_submitted.length > 0 &&
								<>
									<div className='col-12 mt-5'>
										<div className="card rounded shadow-sm">
											<div className={"card-body p-0"}>
												<div className='row m-0'>
													<img className='rounded' src={base.img_borderTop_primary} style={{width : '100%', height : '.75rem'}} />
													<div className='col-12 px-4'>
														<div className='row mt-2'>
															<div className='col-12 p-2 p-lg-4 pb-5'>
																<img className='position-absolute d-none d-lg-block' src={base.img_leaves} style={{height : '5rem', right : 0, top : '-.5rem'}} />
																<div className='row'>
																	<div className='col-12 mb-0 mb-lg-4'>
																		<div className='row'>
																			<div className='col'>
																				<h4 className='m-0 assignmentTitle font-weight-normal' style={{color : '#777777'}}>Your Submission</h4>
																			</div>

																		</div>
																	</div>

																	<div className='col-12'>
																		<div className='row'>
																			<div className='col mt-2'>
																				<p className='m-0'><i className="fas fa-clock mr-2"></i>Submitted on {assignment_data.first_assignment_submitted.submitted_date_format}</p>
																			</div>
																		</div>
																	</div>

																	{
																		query.get('type') != 'task' &&
																		<>
																			{
																				assignment_data.assignment_type.data !== 'ungraded' &&
																				<div className='col-12 mt-4'>
																					<div className='row'>
																						<div className='col-12'>
																							<p className='m-0 font-weight-bold' style={{color : '#777777'}}>Submission</p>
																							<div className='p-2 p-lg-3 border rounded mt-3'>
																								<p className='m-0'>{(submission == null || submission === '' ? '-' : submission)}</p>
																							</div>
																						</div>
																						<div className='col-12 mt-4'>
																							<p className='m-0 font-weight-bold' style={{color : '#777777'}}>Attachment</p>
																							<div className='row mt-3'>
																								{
																									assignment_data.assignment_submitted.map((data, index)=>(
																										<div className='col-auto'>
																											<div className='p-2 position-relative' style={{border : '1px solid #EAEAEA'}}>
																												<h3 className='m-0 assignmentAttachment'><i className="bi bi-file-earmark-fill"></i></h3>
																											</div>
																										</div>
																									))
																								}
																							</div>
																						</div>
																					</div>
																				</div>
																			}
																		</>
																	}
																</div>                                        
															</div>
														</div>
													</div>
												</div>
											</div>
										</div>
									</div>
								</>
							}
						</>
						: <></>
					}


					{
						(assignment_data.last_assignment_submitted != null) &&
						<>
						{
							assignment_data.last_assignment_submitted.assessment_status.data !== 'on_checking' &&
							<div className='col-12 mt-5'>
								<div className="card rounded shadow-sm">
									<div className={"card-body p-0"}>
										<div className='row m-0'>
											<img className='rounded' src={base.img_borderTop_primary} style={{width : '100%', height : '.75rem'}} />
											<div className='col-12 px-4'>
												<div className='row mt-2'>
													<div className='col-12 p-2 p-lg-4 pb-5'>
														<img className='position-absolute' src={base.img_leaves} style={{height : '5rem', right : 0, top : '-.5rem'}} />
														<div className='row'>
															<div className='col-12 mb-0 mb-lg-4'>
																<div className='row'>
																	<div className='col'>
																		<h4 className='m-0 assignmentTitle font-weight-normal' style={{color : 'black'}}>Grade & Correction</h4>
																	</div>

																</div>
															</div>
															<div className='col-12 d-none d-lg-block'>
																<div className='row'>
																	<div className='col-12 col-lg d-flex align-items-center'>
																		<p className='m-0'><i className="fas fa-clock mr-2"></i>Graded on {assignment_data.last_assignment_submitted.updated_date_format}</p>
																	</div>
																	{/* {
																		assignment_data.last_assignment_submitted.chat != null &&
																		<div className='col-12 col-lg mt-3 mt-lg-0 text-right'>
																			<a href={'/lessons/chat?id=' + query.get('id') + '&chat_id=' + assignment_data.last_assignment_submitted.chat.id + '&type=' + query.get('type')} className='btn btn-sm btn-primary py-2 px-3 rounded' disabled>Chat with Teacher
																			</a>
																		</div>
																	} */}
																</div>
															</div>
															<div className='col-12 mt-4'>
																<div className='row'>
																	<div className='col-auto pr-2 pr-lg-2'>
																		<div className='text-center p-2 p-lg-4 py-3 py-lg-4 d-flex align-items-center justify-content-center h-100' style={{backgroundColor : '#EBEFE2', borderRadius : '.5rem'}}>
																			{
																				assignment_type === 'discussion' ?
																				<div>
																					<p className='m-0 text-primary' style={{fontFamily : 'InterBold', fontSize : '1.25rem'}}>Graded</p>
																					<button className='btn btn-primary rounded shadow-sm mt-2' onClick={()=>viewGradeSkill()}>View More</button>
																				</div>
																				:
																				<div className='assignmentScore'>
																					<h2 className='m-0 text-primary'>{assignment_data.last_assignment_submitted.score}</h2>
																					<p className='m-0'>Numerical Grade</p>
																				</div>
																			}
																		</div>
																	</div>
																	<div className='col d-flex align-items-center mt-0 pl-2 pl-lg-2 '>
																		<div className='p-2 p-lg-4 py-3 py-lg-4 h-100 w-100' style={{backgroundColor : '#EBEFE2', borderRadius : '.5rem'}}>
																			<h6 className='m-0 assignmentNotesTitle' style={{color : '#413F3F'}}>Teacher’s Notes</h6>
																			<p className='m-0 mt-2'>{(assignment_type === 'discussion' ? teacher_notes : assignment_data.last_assignment_submitted.comment)}</p>
																		</div>
																	</div>
																</div>
															</div>

															{
																assignment_data.last_assignment_submitted.file_name != null &&
																<div className='col-12 mt-4'>
																	{/* <div className='' style={{height : '15rem', backgroundColor : '#EAEAEA'}}></div> */}
																	<div className='weviewer' style={{height : '100vh'}} ref={viewerDiv}></div>
																</div>
															}
															
															{
																assignment_status_data !== 'done' &&
																<>
																	<div className='col-12 mt-4'>
																		<button className='btn btn-primary rounded py-2 shadow px-5 d-none d-lg-block' onClick={()=>openModalConfirm('correction')}>Submit Correction</button>
																		<button className='btn btn-primary rounded py-2 shadow px-5 d-block d-lg-none w-100' onClick={()=>openModalConfirm('correction')}>Submit Correction</button>
																	</div>
																</>
															}

															{/* {
																assignment_data.last_assignment_submitted.chat != null &&
																<div className='col-12 d-block d-lg-none mt-4'>
																	<a href={'/lessons/chat?id=' + query.get('id') + '&chat_id=' + assignment_data.last_assignment_submitted.chat.id + '&type=' + query.get('type')} className='btn btn-sm btn-primary py-2 px-3 w-100 rounded'>Chat with Teacher<span className='position-absolute' style={{top : '-.5rem', right : '.75rem'}}><i className="fas fa-circle" style={{color : '#FF6262'}}></i></span></a>
																</div>
															} */}
														</div>
														
													</div>
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>
						}

						<ModalViewSkill 
							grade_skill_arr={grade_skill_arr}
							teacher_notes={teacher_notes}
						/>
						</>
					}

					<ModalSubmitAssignment
						submission={submission}
						changeFileInput={(event)=>changeFileInput(event)}
						changeInput={(value)=>changeInput(value)}
						postAssignment={()=>postAssignment()}
						arr_file={arr_file}
						removeAttachment={(index)=>removeAttachment(index)} />
				</>
				:
				<>
					<div className='col-12 mt-3 mt-lg-5'>
						<div className="card rounded shadow-sm">
							<div className={"card-body p-0"}>
								<div className='row m-0'>
									<img className='rounded' src={base.img_borderTop_primary} style={{width : '100%', height : '.75rem'}} />
									<div className='col-12 px-4'>
										<div className='row mt-2'>
											<div className='col-12 p-2 p-lg-4 pb-5 mb-3'>
												<div className='row m-0'>
													<div className='col-12 mb-0 mb-lg-4'>
														<div className='row'>
															<div className='col'>
																<h4 className='m-0 assignmentTitle' style={{color : 'black'}}>Submit Assignment</h4>
															</div>

														</div>
													</div>
													<div className='col-12 mt-2'>
														<p className='m-0' style={{color : '#777777', fontFamily : 'InterBold'}}>Detail Assignment</p>
														<p className='m-0 mt-2' style={{color : '#777777'}}>{assignment_data.description}</p>
													</div>

													<div className='col-12 mt-3'>
														<div className='row'>
															<div className='col-12'>
																<p className='m-0' style={{color : 'black'}}>Input Assignment</p>
																<textarea className="form-control rounded mt-3" rows={3} onChange={(e)=>changeInput(e.target.value)} style={{resize : 'none', border : '1px solid #EAEAEA'}} value={submission} placeholder=""></textarea>
															</div>
															<div className='col-12 mt-4'>
																<p className='m-0' style={{color : 'black'}}>Upload Attachment</p>
																<div className='row'>
																	<div className='col-12'>
																		<div className='row'>
																			{
																				arr_file.map((data, index)=>(
																					<div className='col-auto text-center mt-3' key={index}>
																						<div className='p-2 position-relative' style={{border : '1px solid #EAEAEA'}}>
																							<i className="bi bi-x-circle-fill position-absolute" style={{right : 0, top : 0, cursor : 'pointer'}} onClick={()=>removeAttachment(index)}></i>
																							<h3 className='m-0'><i className="bi bi-file-earmark-fill"></i></h3>
																							<p className='m-0'>{data.name}</p>
																						</div>
																					</div>
																				))
																			}
																		</div>
																	</div>
																</div>

																<input type="file" name="assignment_file-sm" id="file_input-sm" className="d-none" onChange={(event)=>changeFileInput(event)} />
																<button className='btn btn-sm btn-secondary text-white rounded shadow-sm px-5 mt-3' onClick={()=>base.$('#file_input-sm').trigger('click')}>Choose File</button>
															</div>
														</div>
													</div>

													<div className='col-12 mt-4'>
														<button className='btn btn-primary rounded py-2 shadow px-5 d-none d-lg-block' onClick={()=>postAssignment()} disabled={disable_btn_submit}>Confirm Assignment</button>
														<button className='btn btn-primary rounded py-2 shadow px-5 d-block d-lg-none w-100' onClick={()=>postAssignment()} disabled={disable_btn_submit}>Confirm Assignment</button>
													</div> 
												</div>                 
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>

				</>
			}

			<ModalSuccess />
			<ModalConfirm openSubmit={()=>submitAssignment()} />

		</div>
	)
}