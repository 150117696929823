import { useEffect, useState } from 'react';
import Base from '../utils/base';


export default function Header({title, user_data, announcement_arr, is_student_access, selectAnnouncement}){
		var base = new Base()

		const [greeting, set_greeting] = useState('')

		useEffect(async ()=>{
				var currentTime = base.moment().format('HH')
				if (currentTime >= 5 && currentTime < 12){
					await set_greeting("Good Morning")
				} else if (currentTime >= 12 && currentTime < 15){
					await set_greeting("Good Afternoon")
				}   else if (currentTime >= 15 && currentTime < 20){
					await set_greeting("Good Evening")
				} else if (currentTime >= 20 && currentTime < 5){
					await set_greeting("Good Night")
				}
		}, [])

		return(
			<>
			<div className='row'>
				<div className='col-auto d-flex align-items-center'>
					<img className='headerProfileImg' src={user_data.image.image_display} />
				</div>
				<div className='col d-flex align-items-center p-0 p-lg-2'>
					<div className='row headerSection'>
                        <div className='col-12'>
					        <h6 className='m-0 text-primary text-uppercase' style={{fontFamily : 'InterBold'}}>{greeting},</h6>
                        </div>
                        <div className='col-12'>
						    <h2 className='m-0 mt-1 text-capitalize headerName' style={{fontFamily : 'PlayfairDisplayBold'}}>{user_data.name}</h2>
                        </div>
                        <div className='col-12 mt-1'>
                            <div className='row m-0'>
                                <div className='col-auto bg-secondary' style={{borderRadius : '5rem'}}>
						            <p className='m-0 text-uppercase text-white headerDateTime' style={{fontFamily : 'PoetsenOne'}}>{base.moment().format('dddd, DD MMMM YYYY')}</p>
                                </div>
                            </div>
                        </div>
					</div>
				</div>

				{
					is_student_access ?
					<>
						{
							title === 'dashboard' &&
							<>
							<div className='col-auto mt-3 mt-lg-0 d-none d-lg-block w-50'>
								<div className="card rounded shadow-sm">
										<div className={"card-body" + (title === 'dashboard' ? ' p-2' : ' p-3')}>
											<div className={'row' + (title !== 'dashboard' ? ' m-0' : '')}>
												{
														title === 'dashboard' ? 
														<div className='col-12 pr-4 pl-4'>
																<div className='row'>
																		<div className='col-auto d-flex align-items-center position-relative'>
																				<img src={base.img_bell} style={{height : '5rem', left : '-2rem'}} className='position-absolute' />
																				{/* <div className="ratio-11" style={{width : 96, height : '100%', backgroundSize : 'contain', backgroundRepeat : 'no-repeat', backgroundImage : "url(" + base.img_trophy +")"}} ></div> */}
																		</div>
																		<div className='col d-flex align-items-center justify-content-center pl-5'>
																				<div className='row'>
																						<div className='col-12 p-0'>
																								<img className='position-absolute d-none d-lg-block' src={base.img_leaves} style={{height : '2.75rem', right : 0, top : '-.5rem'}} />
																								<div className='row m-0'>
																										<div className='col-12 mb-2 p-0'>
																												<div className='row m-0'>
																														<div className='col-auto d-flex align-items-center justify-content-center'>
																																<h6 className='m-0 homeListTitle' style={{color : 'black', fontFamily : 'InterBold'}}>Announcement</h6>
																														</div>
																												</div>
																										</div>

																										{
																											announcement_arr.length > 0 ?
																											<>
																												{
																													announcement_arr.map((data, index)=>(
																														<div className={'col-12 col-lg-6 mb-2'} key={index} onClick={()=>selectAnnouncement(index)}>
																															<p className='m-0 text-capitalize text-truncate' style={{textDecoration : 'underline', cursor : 'pointer'}}>{data.title}</p>
																														</div>
																													))
																												}
																											</>
																											:
																											<>
																													<div className='col-12 text-center mt-5 mt-lg-0 pt-lg-2 pb-3'>
																															<div className='d-flex align-items-center h-100'>
																																	<p className='noDataTxt m-0' style={{fontFamily : 'Inter', color : 'black'}}>No Data</p>
																															</div>
																													</div>
																											</>
																										}
																								</div>
																						</div>
																				</div>
																				{/* <div>
																						<p className='m-0 text-secondary' style={{fontWeight : 700}}>Congratulations! You’re getting</p>
																						<h3 className='m-0 font-weight-bold'>{user_data.total_today_reward} Awards</h3>
																						<button className='btn btn-sm btn-primary mt-2 w-75' style={{borderRadius : '.5rem'}}>View Rewards</button>
																				</div> */}
																		</div>
																</div>
														</div>
														:
														<>
														{/* <div className='col-12 pl-3 pr-4 py-2'>
																<p className='m-0 text-secondary' style={{fontWeight : 700}}>You’re Currently in</p>
																<h4 className='m-0 font-weight-bold text-capitalize'>{title}</h4>
														</div> */}
														</>
												}
											</div>
												
										</div>
								</div>
							</div>
							<div className='col-12 mt-3 mt-lg-0 d-block d-lg-none'>
								<div className="card rounded shadow-sm">
										<div className={"card-body" + (title === 'dashboard' ? ' p-2' : ' p-3')}>
											<div className={'row' + (title !== 'dashboard' ? ' m-0' : '')}>
												{
													title === 'dashboard' &&
													<div className='col-12 pr-4 pl-4'>
														<div className='row'>
															<div className='col d-flex align-items-center justify-content-center pl-5'>
																<div className='row'>
																	<div className='col-12 p-0'>
																		<img className='position-absolute d-none d-lg-block' src={base.img_leaves} style={{height : '2.75rem', right : 0, top : '-.5rem'}} />
																		<div className='row m-0'>
																			{
																				announcement_arr.length > 0 ?
																				<>
																					{
																						announcement_arr.map((data, index)=>(
																							<div className={'col-12' + (index !== 0 ? ' mt-2' : '')} key={index} onClick={()=>selectAnnouncement(index)}>
																									<div className='row m-0'>
																										<div className='col d-flex align-items-center' style={{textDecoration : 'underline'}}>
																												<p className='m-0 text-capitalize'>{data.title}</p>
																										</div>
																									</div>
																							</div>
																						))
																					}
																				</>
																				:
																				<>
																				<div className='col-12 text-center py-2'>
																					<div className='d-flex align-items-center h-100'>
																						<h6 className='m-0' style={{fontFamily : 'Inter', color : 'black'}}>No Data</h6>
																					</div>
																				</div>
																				</>
																			}
																		</div>
																	</div>
																</div>
															</div>
															<div className='col-auto d-flex align-items-center position-relative'>
																<img src={base.img_bell} style={{height : '3.5rem', right : 0, top : '-1.25rem'}} className='position-absolute' />
															</div>
														</div>
													</div>
												}
											</div>
												
										</div>
								</div>
							</div>
							</>
						}
					</>
					: <></>
				}
			</div>
			</>
		)
}