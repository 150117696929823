import React from 'react';
import Base from '../../utils/base';

import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import HomeIndex from '../home';
import Navbar from './navbar';
import LessonsIndex from '../lessons';
import CalendarIndex from '../calendar';
import ProfileIndex from '../profile';
import LessonsDetail from '../lessons/detail';
import LessonsChat from '../lessons/chat';
import GradeBookIndex from '../profile/gradeBook';
import GradeBookDetail from '../profile/gradeBook/detail';
import AuthLogin from '../auth/login';
import StudentProfileIndex from '../profile/studentProfile';
import TalentIndex from '../profile/talent';
import HabitChallengeForm from '../profile/talent/habitChallengeForm';
import ForgetPassword from '../auth/forgetPass';
import SchoolSchedule from '../calendar/schoolSchedule';
import StudentProfileChangePass from '../profile/changePassword';
import NotificationIndex from '../notification';
import ChatIndex from '../chat';
import ChatRoom from '../chat/room';


// import Navbar from './navbar'
// import SideBar from './sidebar'

export default class BaseLayout extends Base{

  state = {
    token : '',
  }
  
  async componentDidMount(){
    var token = await localStorage.getItem('token')
    await this.setState({token : token})
    // await localStorage.clear()
  }

  render(){
    const { token } = this.state
    return (
      <>
      <Router>
        <div className='position-relative' id="wrapper" style={{width : '100wh', height : '100vh'}}>
            <div className="ratio-169 position-absolute" style={{width : '100%', height : '60rem', backgroundSize : 'cover', backgroundRepeat : 'no-repeat', backgroundImage : "url(" + this.img_background +")"}} ></div>
              
              <div className='background-left d-none d-lg-block'>
                <img src={this.img_logo_talent} />
              </div>
              <div className='background-right d-none d-lg-block'>
                <img src={this.img_logo_talent} />
              </div>

              <div className='position-absolute w-100 pb-5' style={{top : 0}}>
                {
                  token != null &&
                  <Navbar />
                }

                <div className='container pb-5 mb-5 mt-4'>
                  <Routes>
                      <Route exact path={"/"} element={<HomeIndex />}></Route>

                      <Route exact path={"/lessons"} element={<LessonsIndex />}></Route>
                      <Route exact path={"/lessons/detail"} element={<LessonsDetail />}></Route>
                      <Route exact path={"/lessons/chat"} element={<LessonsChat />}></Route>

                      <Route exact path={"/calendar"} element={<CalendarIndex />}></Route>
                      <Route exact path={"/school-schedule"} element={<SchoolSchedule />}></Route>

                      <Route exact path={"/chat"} element={<ChatIndex />}></Route>
                      <Route exact path={"/chat-room"} element={<ChatRoom />}></Route>

                      <Route exact path={"/profile"} element={<ProfileIndex />}></Route>

                      <Route exact path={"/profile/grade-book"} element={<GradeBookIndex />}></Route>
                      <Route exact path={"/profile/grade-book/detail"} element={<GradeBookDetail />}></Route>
                      <Route exact path={"/profile/student-profile/edit"} element={<StudentProfileIndex />}></Route>
                      <Route exact path={"/profile/change-password"} element={<StudentProfileChangePass />}></Route>

                      <Route exact path={"/profile/talent-menu"} element={<TalentIndex />}></Route>
                      <Route exact path={"/profile/talent-menu/habit-challenge"} element={<HabitChallengeForm />}></Route>

                      <Route exact path={"/auth/login"} element={<AuthLogin />}></Route>
                      <Route exact path={"/auth/forget-password"} element={<ForgetPassword />}></Route>

                      <Route exact path={"/notification"} element={<NotificationIndex />}></Route>
                  </Routes>
                </div>
              </div>
              
        </div>
      </Router>
      </>
    );
  }
}