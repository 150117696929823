import { useEffect, useState, createRef } from 'react';
import Base from '../../utils/base';

import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Header from '../../components/header';
import FullCalendar from '@fullcalendar/react' // must go before plugins
import dayGridPlugin from '@fullcalendar/daygrid' // a plugin!
import momentPlugin from '@fullcalendar/moment'
import { Calendar } from '@fullcalendar/core';
import timeGridPlugin from '@fullcalendar/timegrid';
import listPlugin from '@fullcalendar/list';

// import {tui} from 'tui-calendar'
// import 'tui-calendar/dist/'

export default function SchoolSchedule(){
    var base = new Base()

    const [user_data, set_user_data] = useState({id : '', name : '', email : '', phone : '', image : {image_display : base.img_no_profile}, current_class : {id : ''}})
    const calendarRef = createRef()
    const [schedule_arr, set_schedule_arr] = useState([])
    const [current_month, set_current_month] = useState('')

    useEffect(async ()=>{
        var check_user = await base.checkAuth()
        set_user_data(check_user.user_data)

        set_current_month(base.moment().format('MMMM YYYY'))

        var check_student_access_date = check_user.user_data.is_student_access
        if(!check_student_access_date){
            window.location.href = '/'
        }
    }, [])

    useEffect(()=>{
        if(user_data.id !== ''){
            get_data()
            // document.getElementsByClassName('fc-list-event-title').style.background = 'red'
        }
    }, [user_data])

    useEffect(()=>{
        base.$('.fc-list-event').css('background-color', 'blue')
    }, [schedule_arr])

    async function get_data(){
        var url = '/schedule?class_id=' + user_data.current_class.id

        var response = await base.request(url)
        if(response != null){
            if(response.status == 'success'){
                var data = response.data.data
                var event_arr = []
                for(var x in data){
                    var textColor = 'white'
                    var borderColor = 'transparent'
                    var subject_color_arr = ['#FED37E', '#FF91C6', '#6265FF', '#DE496E', '#76D6FC']
                    var result_color = ''
    
                    for(var y in subject_color_arr){
                        result_color = subject_color_arr[Math.floor(Math.random() * subject_color_arr.length)]
                    }

                    if(result_color === '#FED37E'){
                        borderColor = '#F5BA55'
                    }
                    else if(result_color === '#FF91C6'){
                        borderColor = '#FF70B5'
                    }
                    else if(result_color === '#6265FF'){
                        borderColor = '#4D50EE'
                    }
                    else if(result_color === '#DE496E'){
                        borderColor = '#DE496E'
                    }
                    else if(result_color === '#76D6FC'){
                        borderColor = '#3EC2F7'
                    }

                    var title_event = ''
                    var daysOfWeek = []
                    if(data[x].type === 'schedule'){
                        title_event = data[x].subject.name
                        daysOfWeek = [data[x].day]
                    }
                    else{
                        title_event = data[x].name
                        daysOfWeek = [1,2,3,4,5]
                        result_color = '#F1F3F4'
                        textColor = '#999999'
                        borderColor = '#F1F3F4'
                    }

                    
                    event_arr.push({
                        title : title_event,
                        daysOfWeek : daysOfWeek,
                        startTime : data[x].start_time_format,
                        endTime : data[x].end_time_format,
                        color : result_color,
                        textColor : textColor,
                        borderColor : borderColor,
                        // backgroundColor : '#000'
                    })
                }

                console.log(event_arr)
                set_schedule_arr(event_arr)
            }
        }
    }

    function backBtn(){
        window.location.href = '/'
    }

    return(
        <div className='row'>

            <div className='col-12'>
                <Header title={'calendar'} user_data={user_data} />
            </div>

            <div className='col-12 mt-5 pt-4'>
                <div className='row'>
                    <div className='col-auto'>
                        <div className='bg-white shadow-sm rounded d-flex align-items-center justify-content-center backBtn' style={{cursor : 'pointer'}} onClick={backBtn}>
                            <h3 className='m-0'><i className="bi bi-arrow-left-short" style={{color : '#6F826E'}}></i></h3>
                        </div>
                    </div>
                    <div className='col-auto d-flex align-items-center'>
                        <h5 className='m-0'>School Schedule</h5>
                    </div>
                </div>
            </div>


            <div className='col-12 d-none d-lg-block'>
                <FullCalendar
                    ref={calendarRef}
                    plugins={[ timeGridPlugin, momentPlugin ]}
                    initialView="timeGridWeek"
                    headerToolbar={{
                        left : '',
                        right : ''
                    }}
                    allDaySlot={false}
                    slotDuration={'00:15'}
                    slotLabelInterval={'00:15'}
                    slotMinTime={'08:00'}
                    slotMaxTime={'14:00'}
                    slotLabelFormat={{
                        hour: '2-digit',
                        minute: '2-digit',
                        hour12: false
                    }}
                    dayHeaderFormat={{ weekday: 'long' }}
                    eventSources={[
                        {
                            events : schedule_arr,
                        }
                    ]}
                    hiddenDays={[0, 6]}
                    displayEventTime={false}
                />
            </div>

            <div className='col-12 d-block d-lg-none mt-3'>
                <div className='bg-white' id='calendarMobile'>
                    <FullCalendar
                        ref={calendarRef}
                        plugins={[ listPlugin, momentPlugin ]}
                        initialView="listWeek"
                        headerToolbar={{
                            left : '',
                            right : ''
                        }}
                        height={'auto'}
                        allDaySlot={false}
                        eventSources={[
                            {
                                events : schedule_arr,
                            }
                        ]}
                        listDaySideFormat={false}
                        eventTimeFormat={{hour12 : false, hour: "2-digit", hour: '2-digit', minute: '2-digit',}}
                    />
                </div>
            </div>
            
        </div>
    )
}